button[aria-label="Ordered List"] {
    display: none !important;
}

.card-resposta-ia {
    padding: 24px 24px 12px;
    margin: 24px 0 0 0;
    height: 58vh;
    overflow: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    scrollbar-width: 8px !important;
    scrollbar-color: #e0e0e0 transparent !important;
}

.card-resposta-ia::-webkit-scrollbar {
    width: 10px !important;
}

.card-resposta-ia::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin: 10px !important;
}

.card-resposta-ia::-webkit-scrollbar-thumb {
    background-color: #e0e0e0 !important;
    border-radius: 20px !important;
}

.card-resposta-ia .container-resposta-ia pre {
    font-family: 'Arial';
    max-width: 100% !important;
    white-space: pre-wrap;
    hyphens: auto !important;
    font-size: 14px !important;
    text-align: justify !important;
}

.botoes-resposta-ia {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 24px;
}