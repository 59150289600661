/* CSS Reset */

/* Box sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove list styles (bullets/numbers) */
ul, ol {
  list-style: none;
}

/* Remove default margins and paddings */
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre {
  margin: 0;
  padding: 0;
}

/* Typography reset */
body {
  font-family: Arial, sans-serif;
}

/* Other common resets */
a {
  text-decoration: none;
}

button, input, select, textarea {
  background-color: transparent;
  border: none;
  outline: none;
}

/* Add your custom styles below this line */
