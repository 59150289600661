.container {
    padding: 30px;
}

.head-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.head-logo {
    flex-basis: 30%;
}

.head-desc {
    flex-basis: 70%;
}

.rounded-block{
    text-align: center;
    border-radius: 10px;
    border: solid 2px black;
    padding: 10px;
    margin: 10px;
}